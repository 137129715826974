<template>
  <div>
    <page-nav title="问答专区" />
    <div class="form">
      <van-field
        v-model="content"
        rows="5"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="说出您想要问的问题，字数请控制在3-100个。我们将邀请已购用户帮您解答~"
        show-word-limit
      />
      <van-button :disabled="submitDisabled" type="info" block @click="onSubmit">发布问题</van-button>
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils';
import Fetch from '@/common/fetch';
import { ProductAsk } from '@/common/interface';
import Vue from 'vue';
import PageNav from '@/components/PageNav';
import { Field, Toast } from 'vant';

Vue.use(Field);
Vue.use(Toast);

export default {
  name: "AskPage",
  components: { PageNav },
  data() {
    return {
      content: '',
    };
  },
  computed: {
    submitDisabled() {
      return this.content.length < 3 || this.content.length > 100;
    },
  },
  methods: {
    onSubmit() {
      const id = utils.getQueryString('id') || '';
      const params = {
        questionTitle: this.content,
        productId: id,
      }
      Fetch.post(ProductAsk, params).then(() => {
        Toast.success('提问成功');
        this.$router.go(-1);
      }).catch(err => {
        Toast.fail(err.msg || '请求失败');
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  padding: 10px;
}
.form button {
  margin-top: 10px;
}
</style>